<template>
    <div class="animated fadeIn faster">
        <div class="titlebar-con">
            <ar-titlebar
                campaign="Territory Management"
                :from_count="((current_page - 1) * parseInt(page_size)) + 1"
                :to_count="(current_page * parseInt(page_size)) - (parseInt(page_size) - filtered_territories.data.length)"
                :max_count="filtered_territories.total"
                subject="Court(s)"
                img="/static/svg/Territory.svg"
                is_listings
                a_style="border-bottom: 2px solid #dee2e6; padding-left: 32px !important">
                <template v-slot:extra>
                    <div class="ml-3 d-inline-block border-left" style="border-width: 2px !important;">
                        <ar-button
                            icon="fas fa-plus"
                            :is_fab="true"
                            style="padding-top: 8px; padding-bottom:8px;"
                            class="ml-3"
                            @click="showMainModal({}, 0)"
                        >
                            Add Territory
                        </ar-button>
                    </div>
                </template>
                <template v-slot:search-form>
                    <div class="form-inline">
                        <div class="a-input-group">
                            <ar-textfield 
                                @input="setSearchFilter($event)"
                                placeholder         ="Search Territories Here" 
                                type                ="text"
                                a_class             ="mr-1"
                                a_style             ="width: 328px;"
                                icon                ="fas fa-search"
                                :value="filters.search"
                            />
                        </div>
                    </div>
                </template>
            </ar-titlebar>
            <div class="p-2 pl-4">
                <div class="d-inline-block ml-2" style="width:260px">
                    <multiselect
                        @select="setCountryFilter"
                        @remove="setCountryFilter(null)"
                        :value="filters.country"
                        :options="options_region.map(o => o.country)"
                        placeholder="Select Country Here"
                    />
                </div>
                <div v-if="filters.country" class="d-inline-block ml-2">
                    <a
                        href="javascript:void(0)"
                        class="list-btn"
                        @click="filters.country = null"
                        title="Remove country filter">
                        <img src="/static/svg/Delete_Territory.svg">
                    </a>
                </div>
                <div class="d-inline-block ml-5" style="width:260px">
                    <multiselect
                        @select="setRegionFilter"
                        @remove="setRegionFilter(null)"
                        @search-change="dropdown_search = $event.trim()"
                        :value="filters.region"
                        :options="filters.country ? options_region.filter(o => o.country === filters.country) : options_region"
                        placeholder="Select Region Here"
                        group-label="country"
                        group-values="regions"
                        track-by="name"
                        label="name"
                        :group-select="false"
                        :internal-search="false"
                    />
                </div>
                <div v-if="filters.region" class="d-inline-block ml-2">
                    <a
                        href="javascript:void(0)"
                        class="list-btn"
                        @click="filters.region = null"
                        title="Remove region filter">
                        <img src="/static/svg/Delete_Territory.svg">
                    </a>
                </div> 
                <div class="d-inline-block mt-2 mr-3 has-bullet-separator float-right">
                    <ar-button 
                        v-if="filters.region || filters.country"
                        @click="resetFilters()" 
                        class="position-relative font-weight-bold pl-3 a-btn-text btn-text-invalid"
                        style="font-size: 12px; text-align:center; padding-left: 12px !important; padding-right: 12px; padding-top: 3px; padding-bottom: 3px; margin-top: 2px;"
                    >
                        Reset
                    </ar-button>
                </div>
            </div>
        </div>

        <ar-dialog id="new-court-modal" large>
            <div class="add-new-con side-padding">
                <h5>
                    {{ edit_mode ? 'Update' : 'Add' }} Territory
                </h5>
                <div class="method-additional-info animated fadeIn">
                    <div class="row">
                        <div class="col-md-12">
                            <ar-textfield
                                v-model.trim="current.name"
                                @input="is_available = null"
                                a_type="text"
                                :state="is_available === false ? 'invalid' : ''"
                                label="Territory"
                                placeholder="Input here"
                                style="margin-bottom: 0"
                                :err_txt="is_available === false ? 'Territory is not available' : ''"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="col-form-label">Region/State</label>
                            <multiselect
                                @select="$set(current, 'region_id', $event.id)"
                                @remove="$set(current, 'region_id', null)"
                                @search-change="dropdown_search = $event.trim()"
                                :value="object_region[current.region_id]"
                                group-label="country"
                                group-values="regions"
                                track-by="name"
                                label="name"
                                :group-select="false"
                                :options="options_region"
                                :internal-search="false"
                            />
                        </div>
                    </div>
                </div>
                <img src="/static/svg/Database_Photo.svg" alt="Woman standing beside bars bars">
            </div>

            <template v-slot:buttons>
                <ar-button
                    a_class="mr-2"
                    data-dismiss="modal"
                    :outlined="!processing"
                    :disabled="processing"
                >
                    Cancel
                </ar-button>
                <ar-button
                    @click="apply()"
                    :disabled="processing ||
                            !(current.name && current.region_id) ||
                            is_available === false ||
                            (edit_mode && current.name === (filtered_territories.data[current_idx] || {}).name && current.region_id === (filtered_territories.data[current_idx] || {}).region_id) == true"
                >
                    Save
                </ar-button>
            </template>
        </ar-dialog>

        <ar-dialog id="change-status-modal">
            <h4 style="color: #DC3C40;" class="mb-3">{{ filtered_territories.data[current_idx] && filtered_territories.data[current_idx].status === 0 ? 'Activate' : 'Deactivate' }} Territory</h4>
            <p class="text-muted delete-indicator-desc">Do you wish to continue?</p>
            <template v-slot:buttons>
                <ar-button
                    a_class      ="mr-2"
                    data-dismiss ="modal"
                    :outlined="!processing"
                    :disabled="processing">
                    Cancel
                </ar-button>
                <ar-button
                    @click="updateStatus"
                    class="accent"
                >
                    Proceed
                </ar-button>
            </template>
        </ar-dialog>

        <ar-dialog id="delete-modal">
            <h4 style="color: #DC3C40;" class="mb-3">Remove Territory</h4>
            <p class="text-muted delete-indicator-desc">Please input <b>Confirm</b> to remove this territory for security reasons.</p>
            <ar-textfield
                v-model="confirmation"
                placeholder  ="Input here"
                label        ="Type Confirm"
                type         ="text"
                a_class      ="text-left"
                err_txt      ="Note: Textbox is case-sensitive"
            />
            <template v-slot:buttons>
                <ar-button
                    a_class      ="mr-2"
                    data-dismiss ="modal"
                    :outlined="!processing"
                    :disabled="processing">
                    Cancel
                </ar-button>
                <ar-button
                    @click="delete_()"
                    :disabled="confirmation != 'Confirm' || processing"
                    class="accent"
                >
                    Remove
                </ar-button>
            </template>
        </ar-dialog>

        <main class="main-con">
            <div v-if="filtered_territories.data.length">
                <ar-list-item
                    v-for="(territory, index) in filtered_territories.data"
                    :key="index"
                    :id="index"
                    type="location"
                    :checked="territory.status"
                    :active="territory.status"
                    :region="object_region[territory.region_id].name"
                    :country="object_region[territory.region_id].country"
                    :label="territory.name"
                    @box-click="showChangeStatusModal(territory, index)"
                    :clickable-list ="false"
                    disabled
                >
                    <template v-slot:list-buttons>
                        <a href="javascript:void(0)" class="list-btn mr-2" @click="showMainModal(territory, 1, index)" title="Edit Location">
                            <img src="/static/svg/Edit_Territory.svg">
                        </a>
                        <a href="javascript:void(0)" class="list-btn mr-3" @click="showDeleteModal(territory, index)" title="Delete Territory">
                            <img src="/static/svg/Delete_Territory.svg">
                        </a>
                    </template>
                </ar-list-item>
            </div>
            <div v-else class="empty-listing-con">
                <img src="/static/svg/NoData.svg" alt="No Data">
            </div>
        </main>
         <footer class="pagination-footer">
            <div class="d-flex justify-content-between">
                <ar-select
                    @input="setPageSize"
                    :options="page_size_options"
                    :first_element_as_default="true"
                    style="z-index: 1">
                </ar-select>
                <ar-pagination
                    :page_no="current_page"
                    :last_page="filtered_territories.pages"
                    @prev-click="current_page--"
                    @next-click="current_page++"
                    @first-click="current_page = 1"
                    @last-click="current_page = filtered_territories.pages"
                    @enter="gotoPage"
                    @keypress.native="isNumber"
                />
            </div>
        </footer>
    </div>
</template>

<script>
    import axios from '../../../axiosMime'
    import MultiSelect from 'vue-multiselect';

    export default {
        name: "ar-territory",
        components: {
            ArTitlebar: () => import('../../layouts/TitleBar'),
            ArButton: () => import('../../layouts/Button'),
            // ArChip: () => import('../../layouts/Chip'),
            ArListItem: () => import('../../layouts/ListItems'),
            ArTextfield: () => import('../../layouts/Textfield'),
            ArDialog: () => import('../../layouts/Dialog'),
            ArSelect: () => import('../../layouts/Select'),
            ArPagination: () => import('../../layouts/Pagination'),
            'multiselect': MultiSelect
        },
        data() {
            return {
                territories: [],
                processing: false,
                edit_mode: 0,
                current_idx: null,
                current: {},
                is_available: null,
                regions: [],
                filters: {
                    search: null,
                    region: null,
                    country: null
                },
                dropdown_search: null,
                confirmation: null,
                current_page: 1,
                page_size: 12,
                page_size_options: [
                    {
                        label: 'Show 12 items',
                        value: 12
                    },
                    {
                        label: 'Show 30 items',
                        value: 30
                    },
                    {
                        label: 'Show 60 items',
                        value: 60
                    },
                    {
                        label: 'Show 120 items',
                        value: 120
                    }
                ]
            }
        },
        async mounted() {
            this.$parent.showBackdrop();
            await this.getRegions();
            await this.get();
            this.$parent.hideBackdrop();
        },
        computed: {
            filtered_territories() {
                let search = (this.filters.search || '').toLowerCase();
                let result = this.territories.filter(t => {
                    if (this.filters.country && this.object_region[t.region_id].country !== this.filters.country) {
                        return false;
                    }

                    if (this.filters.region && t.region_id !== this.filters.region.id) {
                        return false;
                    }

                    if (this.status !== -1 && t.status !== this.status) {
                        return false;
                    }

                    if (!t.name.toLowerCase().includes(search) &&
                        !this.object_region[t.region_id].name.toLowerCase().includes(search) &&
                        !this.object_region[t.region_id].country.toLowerCase().includes(search)) {
                        return false;
                    }

                    return true;
                }).sort((a, b) => {
                    if (this.order === 'asc') {
                         return (a.name > b.name) - 0.5;
                    }
                    else {
                        return (a.name < b.name) - 0.5;
                    }
                });
                let start = (this.current_page - 1) * parseInt(this.page_size);
                let end = (start + parseInt(this.page_size));

                return {
                    total: result.length,
                    pages: Math.ceil(result.length / parseInt(this.page_size)),
                    data: result.slice(start, end)
                };
            },
            object_region() {
                let result = {};
                this.regions.forEach(r => {
                    result[r.id] = r;
                });
                return result;
            },
            options_region() {
                let result = {};
                let search = (this.dropdown_search || '').toLowerCase();
                this.regions.forEach(r => {
                    if (!r.country.toLowerCase().includes(search) && !r.name.toLowerCase().includes(search)) {
                        return;
                    }

                    if (!result[r.country]) {
                        result[r.country] = { country: r.country, regions: [] };
                    }

                    result[r.country].regions.push(r);
                });
                return Object.values(result);
            },
            status() {
                return this.$route.query.status === undefined ? 1 : parseInt(this.$route.query.status);
            },
            order() {
                return this.$route.query.order === undefined ? 'asc' : this.$route.query.order;
            }
        },
        methods: {
            get() {
                let headers = {headers: {...this.$session.get('admin_headers').headers}};
                headers.headers['Sort'] = "name,asc";
                return axios
                    .get(`${process.env.VUE_APP_URL}/territories?page_size=10000`, headers)
                    .then(response => {
                        this.territories = response.data.data;
                        return true;
                    })
                    .catch(ex => {
                        console.log(ex);
                        this.$parent.showSnackbar('Something went wrong. Please contact system admin', false, 'invalid', 'fas fa-times-circle');
                        return false;
                    });
            },
            getRegions() {
                let headers = {headers: {...this.$session.get('admin_headers').headers}};
                headers.headers['Regions-Only'] = "1";
                headers.headers['Sort'] = "country,asc,name,asc";
                return axios
                    .get(`${process.env.VUE_APP_URL}/regions?page_size=10000&regions_only=1`, headers)
                    .then(response => {
                        this.regions = response.data.data;
                        return true;
                    })
                    .catch(ex => {
                        console.log(ex);
                        this.$parent.showSnackbar('Something went wrong. Please contact system admin', false, 'invalid', 'fas fa-times-circle');
                        return false;
                    });
            },
            checkAvailability() {
                if (this.is_available !== null) {
                    return;
                }

                if (!this.current.name) {
                    return;
                }
                
                this.$parent.showSnackbar('Checking territory availability', true);
                let headers = {headers: {...this.$session.get('admin_headers').headers}};
                headers.headers['Where'] = `{"name": "${this.current.name}"}`;

                return axios
                    .get(`${process.env.VUE_APP_URL}/territories`, headers)
                    .then(({data}) => {
                        return data.data.length === 0;
                    })
                    .catch(err => {
                        this.$parent.showSnackbar('Something went wrong. Please contact system admin', false, 'invalid', "fas fa-check-circle");
                        console.log(err);
                        return null;
                    });
            },
            async apply() {
                this.processing = true;
                let saved = false;
                this.is_available = await this.checkAvailability();
                if (!this.is_available) {
                    this.$parent.showSnackbar('Name is not available', false, 'invalid', "fas fa-check-circle");
                    this.processing = false;
                    return;
                }

                if (this.edit_mode) {
                    saved = await this.update();
                }
                else {
                    saved = await this.add();
                }

                this.processing = false;
                if (saved) {
                    this.$parent.showSnackbar('Saved successfully', false, 'success', 'fas fa-check-circle');
                    $('#new-court-modal').modal('hide');
                    this.setSearchFilter(saved.name);
                    this.filters.region = null;
                    this.filters.country = null;
                    this.$router.push({query : {'status' : saved.status}});
                }
            },
            add() {
                this.$parent.showSnackbar('Adding territory...', true);
                let headers = {headers: {...this.$session.get('admin_headers').headers}};
                headers.headers['Include-Resource-Data'] = 1;
                return axios
                    .post(`${process.env.VUE_APP_URL}/territories`, this.current, headers)
                    .then(({data: {meta: {resource_data}}}) => {
                        this.territories.push(resource_data[0]);
                        return resource_data[0];
                    })
                    .catch(ex => {
                        this.$parent.showSnackbar('Something went wrong. Please contact system admin', false, 'invalid', 'fas fa-check-circle')
                        console.log(ex);
                        return false;
                    });
            },
            update() {
                this.$parent.showSnackbar('Updating....', true);
                let headers = {headers: {...this.$session.get('admin_headers').headers}};
                headers.headers['Include-Resource-Data'] = 1;
                return axios
                    .patch(`${process.env.VUE_APP_URL}/territories/${this.current.id}`, this.current, headers)
                    .then(({data: {meta: {resource_data}}}) => {
                        let idx = this.territories.indexOf(this.filtered_territories.data[this.current_idx]);
                        this.$set(this.territories, idx, {...resource_data[0]});

                        return resource_data[0];
                    })
                    .catch(ex => {
                        this.$parent.showSnackbar('Something went wrong. Please contact system admin', false, 'invalid', 'fas fa-check-circle');
                        console.log(ex);
                        return false;
                    });
            },
            updateStatus() {
                this.$parent.showSnackbar(`${this.current.status == 0 ? 'Activating' : 'Deactivating'}....`, true);

                this.current.status = +(!this.current.status);
                this.processing = true;
                let headers = {headers: {...this.$session.get('admin_headers').headers}};
                headers.headers['Include-Resource-Data'] = 1;
                return axios
                    .patch(`${process.env.VUE_APP_URL}/territories/${this.current.id}`, this.current, headers)
                    .then(({data: {meta: {resource_data}}}) => {
                        this.current = resource_data[0];
                        let idx = this.territories.indexOf(this.filtered_territories.data[this.current_idx]);
                        this.$set(this.territories, idx, {...this.current});
                        this.processing = false;
                        $('#change-status-modal').modal('hide');
                        this.$parent.showSnackbar(`${this.current.status == 1 ? 'Activated' : 'Deactivated'} successfully`, false, 'success', 'fas fa-check-circle');
                        return true;
                    })
                    .catch(ex => {
                        this.$parent.showSnackbar('Something went wrong. Please contact system admin', false, 'invalid', 'fas fa-check-circle');
                        console.log(ex);
                        this.processing = false;
                        return false;
                    });
            },
            delete_() {
                this.$parent.showSnackbar('Deleting territory...', true);

                this.processing = true;
                return axios
                    .delete(`${process.env.VUE_APP_URL}/territories/${this.current.id}`, this.$session.get('admin_headers'))
                    .then(_ => {
                        this.territories.splice(this.territories.indexOf(this.filtered_territories.data[this.current_idx]), 1);
                        this.processing = false;
                        $('#delete-modal').modal('hide');
                        this.$parent.showSnackbar('Deleted successfully', false, 'success', 'fas fa-check-circle');
                        return true;
                    })
                    .catch(ex => {
                        this.$parent.showSnackbar('Something went wrong. Please contact system admin', false, 'invalid', 'fas fa-check-circle');
                        this.processing = false;
                        return false;
                    });
            },
            showMainModal(data, edit_mode, idx = null) {
                this.current = {...data};
                this.edit_mode = edit_mode;
                this.current_idx = idx;
                this.is_available = null;
                $('#new-court-modal').modal({ backdrop: 'static', keyboard: false });
            },
            setPageSize(size) {
                this.page_size = parseInt(size);
                this.current_page = 1;
            },
            setSearchFilter(search) {
                this.filters.search = search.trim();
                this.current_page = 1;
            },
            setRegionFilter(region) {
                this.filters.region = region;
                this.current_page = 1;
            },
            setCountryFilter(country) {
                this.filters.country = country;
                if (country && this.filters.region && this.filters.region.country !== country) {
                    this.filters.region = null;
                }
                this.current_page = 1;
            },
            resetFilters() {
                this.filters.country = null;
                this.filters.region = null;
            },
            isNumber(e) {
                var event = e || window.event;
                var keyCode = event.keyCode || event.which;            
                var key = String.fromCharCode(keyCode);
                var regex = /^[0-9\b]+$/;  

                if (key.length == 0) return;

                if(keyCode == 188 || keyCode == 190) return;
                else if(this.$route.params.case_id != '0' && keyCode == 45) return;
                else {
                    if (!regex.test(key)) {
                        event.returnValue = false;                
                        if (event.preventDefault) event.preventDefault();
                    }
                }    
            },
            showDeleteModal(territory, index) {
                this.current = {...territory};
                this.current_idx = index;
                this.confirmation = null;
                $('#delete-modal').modal({ backdrop: 'static', keyboard: false });
            },
            showChangeStatusModal(territory, index) {
                this.current = {...territory};
                this.current_idx = index;
                this.confirmation = null;
                $('#change-status-modal').modal({ backdrop: 'static', keyboard: false });
            },
            gotoPage() {
                var page_input = document.getElementById('pagination_input');

                if (page_input.value > this.filtered_territories.pages) {
                    page_input.value = this.filtered_territories.pages;
                }
                else if (page_input.value < 1) {
                    page_input.value = 1;
                }

                this.current_page = page_input.value;
                page_input.blur();
            }
        }
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.multiselect__option span, .multiselect__single, .info-status {
    text-transform: capitalize;
}
.multiselect__option--highlight, .multiselect__option--highlight:after {
  background:#480048;
}
</style>
<style scoped>
    .add-new-con.side-padding {
        padding-right: 368px;
    }

    .add-new-con {
        position: relative;
        text-align: left;
        padding: 20px 15px 0;
    }

    .add-new-con.side-padding {
        padding-right: 368px;
    }

    .add-new-con h5 {
        font-weight: 500 !important;
        color: #515365;
        line-height: 24px;
        font-size: 24px;
        margin-bottom: 17px;
    }

    .add-new-con img,
    .add-new-con .availability-card-con {
        position: absolute;
        right: 20px;
        top: 58%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .main-con {
        padding: 210px 344px 150px 84px;
    }

    .main-con table tbody tr td.time-col {
        width: 160px;
        text-align:center;
    }

    .empty-listing-con {
        position:relative;
        height: 28vh;
    }

    .empty-listing-con img {
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        position: absolute;
        width: 100%;
        max-width: 349px;
        left: 50%;
        top: 100%;
    }

    .main-con table tbody tr {
        cursor: pointer;
    }

    .main-con table tbody tr:active {
        background: #ddd;
    }

    .main-con table tbody tr td.notification-details {
        letter-spacing: 0.4px;
    }

    .main-con table tbody tr td.notification-details img {
        height: 20px;
        width: 20px;
    }

    .d-inline >>> .text-break {
        display:inline !important;
    }

    .d-inline >>> br:after {
        content: ' ';
    }
    .d-inline >>> br {
        content: ' ';
    }

    .unread {
        background: #ececec;
    }

    tbody tr {
        border-bottom: 1px solid #dcdada;
    }

    #import-listing-modal h5 {
        font-size: 16px;
        color: #515365;
    }

    #import-listing-modal h5 span {
        font-size: 12px;
        line-height: 16px;
        margin-top: 3px;
    }

    #import-listing-modal .left-con,
    #import-listing-modal .right-con {
        max-width: 400px;
        overflow-y: auto;
    }

    #listing-details-modal h5 {
        font-size: 16px;
        color: #515365;
        width: 80%;
    }

    #listing-details-modal h5 span {
        font-size: 12px;
        line-height: 16px;
        margin-top: 3px;
    }

    #listing-details-modal .left-outer-con {
        background: #f0f4ff;
        border-radius: 8px;
        margin-top: 6px;
        padding: 20px 18px;
        min-height: 470px;
    }

    #listing-details-modal .counter-image {
        height: 42px;
        width: 42px;
        position: relative;
        top: -5px;
    }

    #listing-details-modal .left-con,
    #listing-details-modal .right-con {
        max-width: 400px;
        font-size: 12px;
        height: 318px;
        overflow-y: auto;
        overflow-x: hidden;
        color: #515365;
    }

    #listing-details-modal .left-con img {
        height: 12px;
        width: 12px;
        margin-right: 10px;
    }

    #listing-details-modal .comments-con {
        -webkit-transition: height 0.5s ease;
        transition: height 0.5s ease;
        overflow-y: auto;
        height: 346px;
    }

    #listing-details-modal .comments-con.lessen {
        height: 281px;
    }

    .comment-con {
        -webkit-transition: background .5s ease;
        transition: background .5s ease;
        padding: 17px;
        padding-left: 58px;
        border-radius: 8px;
    }

    .comment-con:hover {
        background: #f7f8fb;
    }

    .comment-con .delete-comment-btn {
        opacity: 0;
        float: right;
    }

    .comment-con .delete-comment-btn img {
        height: 12px;
        width: 12px;
    }

    .comment-con:hover .delete-comment-btn {
        opacity: 1;
    }

    .comment-con img.icon {
        position:absolute;
        height: 40px;
        width: 40px;
        left: 10px;
        top: 17px;
        border-radius: 100%;
    }

    .comment-details-con h5 {
        color: #515365;
        font-size: 16px;
        margin-bottom: 2px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 85%;
        line-height: 100%;
    }

    .comment-details-con .text-muted {
        color: #999a9b !important;
        font-size: 11px !important;
    }

    .comment-details-con-con {
        letter-spacing: 0.3px;
        font-size: 13px;
    }

    .comments-con .active-delete .comment-con:hover {
        background: #fae3e4;
    }

    .comments-con .active-delete {
        background: #fae3e4;
        border-radius: 8px;
    }

    .comments-con .active-delete .delete-comment-btn {
        opacity: 1;
    }

    .remove-confirm-text {
        margin-bottom: 0;
        font-size: 10px;
    }
    
    .remove-confirm-btn {
        -webkit-transition: opacity .5s ease;
        transition: opacity .5s ease;
        display: inline-block;
        width: 24px;
        cursor: pointer;
    }

    .remove-confirm-btn img {
        width: 100%;
    }
    
    .remove-confirm-btn:hover {
        opacity: 0.5;
    }

    .remove-confirm-con,
    .remove-confirm-con .a-modal-seller-info-item {
        background: #eaeef5;
    }

    .col-form-label {
        color: #383A3D;
        margin-left: 5px;
        font-weight: 500 !important;
        font-size: 13px;
    }

    .from-label {
        padding-top:0px;
    }

    .form-control {
        font-size: 14px;
    }

    .apply-button {
        margin-top:10px;
    }

    .table-hover tr:hover {
        background: #d8d8d8 !important;
    }

    .input-icon {
        position: absolute;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        top: 50%;
        right: 18px;
    }

    .titlebar-con {
        padding-left: 46px;
        padding-right: 311px;
    }

    .has-bullet-separator {
        position: relative;
    }

    .has-bullet-separator::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        z-index: 2;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        height: 5px;
        width: 5px;
        border-radius: 100%;
    }

    .add-img-con {
        margin-top: 96px;
    }
    
    .pagination-footer {
        background: #fff;
        position: fixed;
        padding: 10px 344px 0 80px;
        height: 60px;
        width: 100%;
        bottom: 0;
        left: 0;
    }

    .counterfeit-checker-history-con {
        overflow: auto;
        min-height: 398px;
        max-height: 380px;
        padding: 10px;
        padding-right: 29px;
    }

    .empty-history-state {
        width: 76%;
        text-align:center;
        padding-top: 30px;
        margin: 0 24px 0 auto;
    }

    .empty-history-state img {
        width: 100%;
    }

    #matches-found-modal h5 {
        font-size: 24px;
        font-weight: 500;
        color: #DC1A1A;
    }

    #matches-found-modal h5 span {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.24px;
        color: #53606DB3;
        display: block;
        margin-top: 5px;
    }

    #matches-found-modal table tr,
    #import-sheet table tr {
        font-size: 12px;
    }

    #matches-found-modal table thead tr,
    #import-sheet table thead tr {
        background: #F5F6FA;
        border-bottom: 2px solid rgb(214, 214, 216);
        color: #202229;
    }

    #matches-found-modal table tbody tr {
        border-bottom: 2px solid #F5F6FA;
        background: #DC1A1A52;
    }

    #matches-found-modal table tbody tr:last-child {
        border-bottom: none;
    }

    #import-sheet table tbody tr {
        border-bottom: none;
    }

    .table-scroll {
        width:100%;
        display: block;
        empty-cells: show;
    }

    .table-scroll thead,
    .table-scroll tfoot{
        position:relative;
        display: block;
        width:100%;
    }

    .table-scroll tbody{
        display: block;
        position:relative;
        width:100%;
        height: 280px;
        overflow-y:auto;
    }

    .table-scroll tr{
        width: 100%;
        display: inline-block;
    }

    .table-scroll td,.table-scroll th{
        display: inline-block;
        word-break: break-all;
        padding: 1rem;
        text-align:left;
    }

    #import-sheet .table-scroll tbody {
        height: 232px;
    }

    /*
    * CM VARIATIONS
    */
    .gui-cm .has-bullet-separator::before {
        background: #2D599E;
    }
    /*
    * END CM VARIATIONS
    */
</style>