import Vue from 'vue'

export default Vue.directive('show-feature', (el, binding, vnode) => {
  const excludedFeatures = /* vm.$store.getters['featureAccessManagement/excludedFeatures'] */ []
  const feature = binding.value
  const isInExcludedFeatures = excludedFeatures.includes(feature)

  /* if (isInExcludedFeatures) {
    // replace HTMLElement with comment node
    const comment = document.createComment(' ');
    Object.defineProperty(comment, 'setAttribute', {
      value: () => undefined,
    });

    vnode.elm = comment;
    vnode.text = ' ';
    vnode.isComment = true;
    vnode.context = undefined;
    vnode.tag = undefined;
    vnode.data.directives = undefined;

    if (vnode.componentInstance) {
      vnode.componentInstance.$el = comment;
    }

    if (el.parentNode) {
      el.parentNode.replaceChild(comment, el);
    }
  } */
});
