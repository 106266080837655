// @ts-check

import { isFeatureRestricted } from '../../utils/common';
import Vue from 'vue';

/**
 * Behaves like v-if but for specified features.
 * @returns 
 */
export const useRestrictFeature = () => {
  const directive = Vue.directive('restrict', (el, binding, node) => {
    /** @type { string | string[] } */
    const feature = binding.value ?? '';

    const hasHideModifier = binding.modifiers?.hide ?? false;

    const isBindingValueArray = Array.isArray(feature);

    if (!isBindingValueArray && hasHideModifier) {
      el.setAttribute('feature-name', feature);
    }

    const shouldHideElement = isBindingValueArray
      ? feature.every(item => isFeatureRestricted(item.replace(/ /g, '-')))
      : isFeatureRestricted(feature.replace(/ /g, '-'));

    if (!shouldHideElement) {
      if (hasHideModifier) {
        el.style.display = '';
      }

      return;
    }

    if (hasHideModifier) {
      el.style.display = 'none';
      return;
    }

    const comment = document.createComment(' ');

    Object.defineProperty(comment, 'setAttribute', {
      value: () => undefined,
    });

    node.elm             = comment;
    node.text            = ' ';
    node.isComment       = true; 
    node.context         = undefined;
    node.tag             = undefined;
    node.data.directives = undefined;

    if (node.componentInstance) {
      // @ts-ignore
      node.componentInstance.$el = comment;
    }

    if (el.parentNode) {
      el.parentNode.replaceChild(comment, el);
    }
  });

  return directive;
}
