<template>
    <div class="animated fadeIn faster">
        <div class="titlebar-con">
            <ar-titlebar
                :heading ="titlebar_heading"
                :a_class="titlebar_class"
                :a_style="titlebar_style"
                :has_image="false">
                <template>
                    <div class="form-inline">
                        <div class="a-input-group">
                            <ar-textfield 
                                :placeholder ="textfield_placeholder" 
                                type        ="text" 
                                a_style     ="width: 328px"
                                icon        ="fas fa-search"
                                v-model     ="searched_campaign"
                                @keyup.enter.native.exact="searchedCampaign(searched_campaign)"
                                @keyup.ctrl.delete.native ="clearSearchedCampaign()"
                                :disabled   ="disable_search">
                            </ar-textfield>
                        </div>
                    </div>
                </template>
            </ar-titlebar>
        </div>

        <main class="main-con">
            <h5 class="a-header d-flex justify-content-between pr-3">
                <div>
                    <span>Showing all {{ campaigns_from_count || 0 }}-{{ campaigns_to_count || 0 }} out of {{ campaigns_meta.total_no_of_items }} {{campaigns.length > 1 ? 'campaigns' : 'campaign'}}</span>
                </div>
                <div>
                    <div class="d-flex justify-content-between campaign-filters-con">
                        <div>
                            <div class="btn-group mr-2 order-by-buttons" role="group" aria-label="Basic example">
                                <button 
                                    title="Order Campaigns by Ascending"
                                    type="button"
                                    class="btn btn-default"
                                    :class="{'active': order_by == 'asc'}"
                                    @click="order_by = 'asc'"
                                    :disabled="disable_search">
                                    <i class="fas fa-sort-alpha-up-alt"></i>
                                </button>
                                <button 
                                    title="Order Campaigns by Descending"
                                    type="button"
                                    class="btn btn-default"
                                    :class="{'active': order_by != 'asc'}"
                                    @click="order_by = 'desc'"
                                    :disabled="disable_search">
                                    <i class="fas fa-sort-alpha-down-alt"></i>
                                </button>
                            </div>
                        </div>
                        <ar-select 
                            class="d-inline-block"
                            a_style="position: relative; top: -1px;"
                            v-model="show_number_of_items"
                            :options="show_number_options"
                            :disabled="disable_search">
                            Show 12 items
                        </ar-select>
                    </div>
                </div>
            </h5>

            <div class="card-con">
                <ar-expandable 
                    v-for             ="(campaign, index) in campaigns" 
                    :key              ="index" 
                    :logo             ="campaign.logo" 
                    :lawfirm          ="campaign.lawfirm"
                    :campaign         ="campaign.campaign" 
                    :total_products   ="campaign.total_products" 
                    :total_product    ="campaign.total_product" 
                    :id               ="campaign.id"
                    class             ="animated fadeIn fast"
                    @click            ="goToListing(campaign.id,campaign.campaign,campaign.lawfirm_id)"
                    @toggle-click     ="toggle(campaign.id)"
                >
                </ar-expandable>
                <div v-if="!campaigns.length && loaded" class="empty-campaign-con">
                    <img src="/static/svg/NoCampaigns_Photo.svg" alt="No campaigns">
                </div>
            </div>
            <div v-if="campaigns.length && loaded && last_page > 1">
                <ar-pagination
                    class="animated fadeIn"
                    :page_no="current_page"
                    :last_page="last_page"
                    @first-click="updatePage(1)"
                    @prev-click="updatePage(prev_page)"
                    @next-click="updatePage(next_page)"
                    @last-click="updatePage(last_page)"
                    @enter="updateListingPage"
                    @keypress.native="isNumber"
                />
            </div>
        </main>
    </div>
</template>

<script>
    import expandable from '../../../components/layouts/Expandable'
    import textfield from '../../layouts/Textfield'
    import titlebar from '../../layouts/TitleBar'
    import select from '../../layouts/Select'
    import Masonry from 'masonry-layout'
    import pagination from '../../layouts/Pagination'
    import axios from '../../../axiosMime'
    import {mapActions} from 'vuex'
    export default {
        components: {
            'ar-expandable': expandable,
            'ar-textfield': textfield,
            'ar-titlebar': titlebar,
            'ar-select': select,
            'ar-pagination': pagination
        },
        data() {
            return {
                titlebar_heading: "All Campaigns",
                textfield_placeholder: "Search Campaigns",
                titlebar_class: "",
                titlebar_style: "",
                campaignsx: [{id:"x",logo:"/static/svg/Campaign Default Photo.svg",lawfirm:"Test Law LLC",campaign:"Dave Gwapo",total_product:4,open:1,rework:1,researched:1,qualified:1},
                {id:"y",logo:"/static/svg/Campaign Default Photo.svg",lawfirm:"Test Law LLC",campaign:"Marvin Gwapo",total_product:120,open:10,rework:99,researched:20,qualified:20},
                {id:"a",logo:"/static/svg/Campaign Default Photo.svg",lawfirm:"Test Law LLC",campaign:"Joe Gwapo",total_product:120,open:10,rework:99,researched:20,qualified:20},
                {id:"b",logo:"/static/svg/Campaign Default Photo.svg",lawfirm:"Test Law LLC",campaign:"Joesenh Gwapo",total_product:120,open:10,rework:99,researched:20,qualified:20},
                {id:"c",logo:"/static/svg/Campaign Default Photo.svg",lawfirm:"Test Law LLC",campaign:"Michael Gwapo",total_product:120,open:10,rework:99,researched:20,qualified:20},
                {id:"d",logo:"/static/svg/Campaign Default Photo.svg",lawfirm:"Test Law LLC",campaign:"Ed Gwapo",total_product:120,open:10,rework:99,researched:20,qualified:20},
                {id:"e",logo:"/static/svg/Campaign Default Photo.svg",lawfirm:"Test Law LLC",campaign:"Job Gwapo",total_product:120,open:10,rework:99,researched:20,qualified:20},
                ],
                campaigns:[],
                searched_campaign:"",
                msnry:"",
                loaded: false,
                campaigns_meta: {},
                timer: '',
                page_size: 12,
                current_page: 1,
                disable_search:false,
                sort_by: 'name',
                order_by: 'asc',
                show_number_of_items: 0,
                show_number_options:[{
                    label: "Show 25 items",
                    value: 1
                },
                {
                    label: "Show 50 items",
                    value: 2
                },
                {
                    label: "Show 100 items",
                    value: 3
                }]
            }
        },
        computed:{
            next_page() {
                var page = this.current_page

                if (this.current_page < this.last_page) page += 1

                return page
            },
            prev_page() {
                var page = this.current_page

                if (this.current_page > 1) page -= 1

                return page
            },
            campaigns_from_count() {
                return (this.current_page > 1) ? ((this.current_page * this.page_size) - this.page_size) + 1 : this.campaigns.length > 0 ? 1 : 0  
            },
            campaigns_to_count() {
                return ((this.current_page * this.page_size) - this.page_size + this.campaigns_meta.items_in_this_page)
            },
            last_page() {
                return this.campaigns_meta.no_of_pages
            },
            access_level(){
                return JSON.parse(localStorage.getItem("lawfirm")).access_level
            }
        },
        beforeCreate(){
            if(localStorage.getItem("token") == null){
                this.$router.push('/lawfirm')
            }
        },
        mounted(){
            document.title = this.$route.meta.title
            this.getCampaigns()
            this.showSidebar(true)
            this.showNavbar(true)
            this.showFilterDrawer(false)
            this.greyBg(true)
        },
        methods:{
            ...mapActions(["showSidebar","showNavbar","showFilterDrawer", "greyBg"]),
            getCampaigns(){
                // Loading Snackbar
                this.disable_search = true
                this.$parent.showBackdrop()
                let headers = JSON.parse(JSON.parse(localStorage.getItem("lawfirm")).headers)

                headers.headers.Sort = `${this.sort_by},${this.order_by}`

                axios.get(process.env.VUE_APP_URL+`/lawfirm_campaigns?page_size=${this.page_size}&page=${this.current_page}`, headers)
                .then(response=>{
                    let results = response.data
                    this.campaigns = results.data.map(campaign => {
                        let obj = campaign.lawfirm_id != 9
                            ? { 
                                'For Review' : campaign.total_items_by_status['qualified'],
                                'Monitoring' : campaign.total_items_by_status['monitoring'],
                                'Accepted' : campaign.total_items_by_status['accepted']
                            }
                            : { 
                                'Has Potential' : campaign.total_items_by_status['has potential'],
                                'For Review' : campaign.total_items_by_status['qualified'],
                                'Monitoring' : campaign.total_items_by_status['monitoring'],
                                'Accepted' : campaign.total_items_by_status['accepted']
                            }

                        let total = campaign.lawfirm_id != 9
                            ? campaign.total_items - campaign.total_items_by_status['has potential']
                            : campaign.total_items

                        return {
                            id:campaign.id,
                            logo: this.$parent.getImage('campaigns', campaign.name),
                            lawfirm:campaign.lawfirm,
                            lawfirm_id:campaign.lawfirm_id,
                            campaign:campaign.name,
                            total_product: total,
                            total_products: obj
                        }
                    })
                    this.campaigns_meta = results.meta
                    this.loaded = true
                    this.disable_search = false
                    this.initializeGrid()
                    this.$parent.hideBackdrop()
                })
            },
            initializeGrid() {
                var _grid = document.querySelector('.card-con')
                var _cards = document.getElementsByClassName('a-card-toggle')
                
                this.msnry = new Masonry( _grid, {
                    itemSelector: '.a-card-sm',
                    gutter: 10,
                    transition: '0.1s',
                    horizontalOrder: true,
                    stagger: 10
                })

                // document.querySelector('.a-textfield input').addEventListener('keyup', () => {
                //     this.updateGrid()
                // })
                
                setTimeout(() => {
                    this.updateGrid()
                }, 200);
            },
            toggle(id) {
                document.getElementById(id).classList.toggle('toggled')
                setTimeout(() => {
                    this.msnry.reloadItems()
                    this.msnry.layout()
                }, 180)
            },
            updateGrid() {
                setTimeout(() => {
                    if (document.getElementsByClassName('a-card-sm') != null) {
                        [...document.getElementsByClassName('a-card-sm')].forEach(el => {
                            el.classList.remove('toggled')
                        })
                    }

                    setTimeout(() => {
                        this.msnry.reloadItems()
                        this.msnry.layout()
                        setTimeout(() => {
                            this.msnry.reloadItems()
                            this.msnry.layout()
                        }, 1)
                    }, 180)
                })
            },
            goToListing(id,lawfirm_id){
                this.$router.push({name:"lawfirmlisting",params:{campaign_id:id, status : lawfirm_id == 9 ? 'Has Potential' : 'For Review',
                    case_id : 0}})
            },
            searchedCampaign(campaign){
                if(campaign.trim() != ""){
                    this.disable_search = true
                    this.current_page = 1
                    this.$parent.showBackdrop()
                    let config = JSON.parse(JSON.parse(localStorage.getItem("lawfirm")).headers)
                    // config.headers.where = JSON.stringify({"user_id": JSON.parse(localStorage.getItem('ver')).id, "campaign_id": { "campaigns" : { "name" : (campaign).trim() }}})
                    axios.get(process.env.VUE_APP_URL+`/lawfirm_campaigns/search/${(campaign).trim()}?page_size=${this.page_size}&page=${this.current_page}`,config)
                    .then(response=>{
                        let results = response.data
                        this.campaigns = results.data.map(campaign=>{
                            return {
                                id:campaign.id,
                                logo: this.$parent.getImage('campaigns', campaign.name),
                                lawfirm:campaign.rightsholder,
                                campaign:campaign.name,
                                total_product:campaign.total_items,
                                total_products: {
                                    'Has Potential' : campaign.total_items_by_status['has potential'],
                                    'For Review' : campaign.total_items_by_status['qualified'],
                                    'Monitoring' : campaign.total_items_by_status['monitoring'],
                                    'Accepted' : campaign.total_items_by_status['accepted']
                                }
                            }
                        })
                        this.campaigns_meta = results.meta
                        this.loaded         = true
                        this.initializeGrid()
                        this.$parent.hideBackdrop()
                        this.disable_search = false
                        setTimeout(() => document.querySelector('.a-textfield input').focus())
                    })
                }else{
                    this.getCampaigns()
                }
            },
            clearSearchedCampaign() {
                this.searchedCampaign(this.searched_campaign = "")
            },
            updatePage(page) {
                this.current_page = page
                this.getCampaigns()
                window.scrollTo(0, 0)
            },
            updateShowNumberItems() {
                switch (this.show_number_of_items) {
                    case "1":
                        this.page_size = 25
                        break
                    case "2":
                        this.page_size = 50
                        break
                    case "3":
                        this.page_size = 100
                        break
                    default:
                        this.page_size = 12
                        break
                }
            },
            updateListingPage() {
                var page_input = document.getElementById('pagination_input')

                if (page_input.value > this.last_page) page_input.value = this.last_page
                else if (page_input.value < 1) page_input.value = 1

                this.updatePage(page_input.value)
            },
        },
        watch: {
            order_by() {
                this.getCampaigns()
            },
            sort_by() {
                this.getCampaigns()
            },
            show_number_of_items() {
                this.current_page = 1
                this.updateGrid()
                this.updateShowNumberItems()
                this.getCampaigns()
            }
        }
    }
</script>

<style scoped>
    .main-con {
        padding: 158px 10px 150px 87px;
    }

    .a-header {
        letter-spacing: 0.024em;
        color: #383A3D;
        font-weight: 500;
        font-size: 24px;
        margin-bottom: 32px;
    }

    .a-header span {
        display: block;
        font-size: 16px;
        font-weight: 400;
        color: #383A3D;
        margin-top: 8px;
    }

    .card-con {
        text-align: left;
    }

    .card-con .a-card {
        position: absolute; 
    }

    .empty-campaign-con {
        position:relative;
        height: 28vh;
    }

    .empty-campaign-con img {
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        position: absolute;
        width: 30%;
        left: 50%;
        top: 102%;
    }

    .order-by-buttons button.active,
    .sort-by-buttons button.active {
        background: #C2DEF9;
    }

    .campaign-filters-con {
        width: 239px;
        max-width: 100%;
    }

    .titlebar-con {
        padding-left: 46px;
    }
</style>
